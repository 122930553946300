<!-- 订单管理-充电订单 -->

<template>
	<div class="top-filter">
		<el-input placeholder="请输入优惠券名称查询" v-model="keywords" @keydown.enter="tableLoad" clearable class="search-input"
			:suffix-icon="Search"></el-input>
		<el-button type="primary" round @click="showCreateCoupons"
			v-if="authData.indexOf('n_79P8UQ0cwsByZepOJKFpHFtj3pHH') != -1">
			<el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
			创建优惠券
		</el-button>
	</div>
	<div class="order-charge main-cnt" style="height:calc(100% - 170px);">

		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 380px)" :ischeck="false" :ispaging="true"
				:extraParame="{keywords:keywords}" :apiName="Coupons.couponsList" :filters="filters"
				:columns="tableColumns" @showrefUseStatisticsBtn="showrefUseStatisticsBtn">
				<template #c_grant_stime="{row}">
					<div>{{row.c_grant_stime}}-{{row.c_grant_etime}}</div>
				</template>
				<template #c_use_stime="{row}">
					<div v-if="row.c_period_type == 1">{{row.c_use_stime}}<span
							v-if="row.c_use_etime">-{{row.c_use_etime}} </span> </div>
					<div v-if="row.c_period_type == 2">{{row.c_period_mark}}{{row.c_period_time}}天 </div>
				</template>

				<template #c_over="{row}">
					<div class="flex align-center">
						<div class="dots" :class="row.c_over == '是' ? 'bg-green' : 'bg-orange'"></div>
						<div>{{ row.c_over }}</div>
					</div>
				</template>

				<template #ct_mark="{row}">
					<div
						:class="{'green-tag':row.c_ct_id == 1,'blue-tag':row.c_ct_id == 2,'orange-tag':row.c_ct_id == 3}">
						<span v-if="row.c_ct_id == 1">满{{row.c_factor}}</span>
						<span v-if="row.c_ct_id == 2">{{row.c_factor}}</span>
						<span v-if="row.c_ct_id == 3">立减{{row.c_factor}}</span>
					</div>
				</template>
				<template #c_organ="{row}">
					<div v-if=" typeof(row.c_organ) == 'object' " class="flex">
						<div>{{row.c_range}} </div>
						<div class="circle" @click="showRange(row)">{{row.c_organ.length}}</div>
					</div>
					<div v-else>{{row.c_range}}-{{row.c_organ}}</div>
				</template>
				<template #c_limit_factor="{row}">
					<div class="grey-txet">
						{{row.c_limit}}{{row.c_limit_factor}}
					</div>
				</template>
				<template #getCount="{row}">
					<div class="grey-txet">
						{{row.getNum}}({{row.getCount}}%)
					</div>
				</template>
				<template #useCount="{row}">
					<div class="grey-txet">
						{{row.useNum}} ({{ row.useCount}}%)
					</div>
				</template>
				<template #provideCoupons="{row}">
					<el-button @click="provideCoupons(row)"
						v-if="authData.indexOf('n_FDb4wD1Z5h8pJiH1KRqdDZEt4pqZ') != -1 && row.c_grant_way == 1">发券</el-button>
				</template>
			</common-table>
		</div>
		<!-- 创建优惠券 -->
		<addCoupons ref="refCreateCoupons" :tableLoad="tableLoad"></addCoupons>
		<!-- 使用统计 -->
		<useStatistics ref="refUseStatistics"></useStatistics>
		<chooseMerchant ref="refChooseMerchant"></chooseMerchant>
		<!-- 发券 -->
		<issuingVouchers ref="refIssuingVouchers" @onConfirm="onConfirm"></issuingVouchers>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
	} from "vue";
	import {
		Coupons,
	} from "@/plugins/api.js";
	import {
		ElMessage,
	} from "element-plus";
	import {
		useStore
	} from "vuex";

	import {
		Search
	} from "@element-plus/icons-vue";
	import addCoupons from '../components/addCoupons.vue'
	import useStatistics from '../components/useStatistics.vue'
	import chooseMerchant from '../components/chooseMerchant.vue'
	import issuingVouchers from '../components/issuingVouchers.vue';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	const keywords = ref('')
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})

	const refChooseMerchant = ref(null)


	const showRange = (row) => {
		if (row.c_range_value == 2) { // 适用范围为商家
			let s_ids = row.c_organ.map(res => {
				return res.s_id
			})
			refChooseMerchant.value.openChooseMerchant(s_ids)
		}
	}

	/** 表格对象 */
	const roleTable = ref(null);

	/** 筛选条件列表 */
	const filters = ref([{
			filterType: "select",
			name: "ct_id",
			value: "",
			placeholder: "优惠类型",
			options: [{
				id: '1',
				name: '满减券'
			}, {
				id: '2',
				name: '折扣券'
			}, {
				id: '3',
				name: '立减券'
			}],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "range",
			value: "",
			placeholder: "适用范围",
			options: [
				{
					id: '1',
					name: '平台券'
				},
				{
					id: '2',
					name: '商家券'
				}, 
			],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "grant_type",
			value: "",
			placeholder: "发放类型",
			options: [{
				id: '1',
				name: '平台发放'
			}, {
				id: '2',
				name: '主动领取'
			}],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "target",
			value: "",
			placeholder: "适用对象",
			options: [{
				id: 1,
				name: '不限制'
			}, {
				id: 2,
				name: '新用户'
			}, {
				id: 3,
				name: '老用户'
			}],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "get_sort",
			value: "",
			placeholder: "领用排序",
			options: [{
				id: 'asc',
				name: '正序'
			}, {
				id: 'desc',
				name: '倒序'
			}],
			val: "id",
			lab: "name",
		},
		{
			filterType: "select",
			name: "c_status",
			value: 2,
			placeholder: "状态",
			options: [{
				id: 1,
				name: '未生效'
			}, {
				id: 2,
				name: '已生效'
			}, {
				id: 3,
				name: '已过期'
			}],
			val: "id",
			lab: "name",
		}
	]);

	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "c_id",
			label: "ID",
			showTooltip: true,
		},
		{
			prop: "c_title",
			label: "优惠券名称",
			color: "--text-color",
			showTooltip: true,
			minWidth: 160
		},
		{
			prop: "c_num",
			label: "生成数量",
			color: "--text-color",
			showTooltip: true,
			minWidth: 80
		},
		{
			type: 'customRender',
			prop: "c_grant_stime",
			label: "发放时间",
			color: "--text-color",
			showTooltip: true,
			minWidth: 190
		},
		{
			type: 'customRender',
			prop: "c_use_stime",
			label: "使用有效期",
			color: "--text-color",
			showTooltip: true,
			minWidth: 190
		},
		{
			prop: "c_over",
			type: 'customRender',
			label: "是否叠加",
			color: "--text-color",
			showTooltip: true,
			minWidth: 80
		},
		{
			type: 'customRender',
			prop: "ct_mark",
			label: "优惠类型",
			showTooltip: true,
			minWidth: 120
		},
		{
			type: 'customRender',
			prop: "c_organ",
			label: "适用范围",
			color: "--text-color",
			showTooltip: true,
			minWidth: 220,

		},
		{
			prop: "c_grant_type",
			label: "发放类型",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			prop: "c_target",
			label: "使用对象",
			color: "--text-color",
			showTooltip: true,
			minWidth: 80
		},
		{
			type: 'customRender',
			prop: "c_limit_factor",
			label: "领券限制",
			color: "--text-color",
			showTooltip: true,
			minWidth: 110
		},
		{
			prop: "c_remark",
			label: "备注",
			color: "--text-color",
			showTooltip: true,
			minWidth: 150
		},
		{
			type: 'customRender',
			prop: "getCount",
			label: "领用合计",
			color: "--text-color",
			showTooltip: true,
			minWidth: 100
		},
		{
			type: 'customRender',
			prop: "useCount",
			label: "使用合计",
			color: "--text-color",
			showTooltip: true,
			minWidth: 100
		},
		{
			prop: "u_name",
			label: "创建人",
			color: "--text-color",
			showTooltip: true,
			minWidth: 70
		},
		{
			prop: "c_created_time",
			label: "创建时间",
			color: "--text-color",
			showTooltip: true,
			minWidth: 160
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 200,
			bottons: [{
				type: 'customRender',
				name: "发券",
				action: "provideCoupons",
				token: "n_FDb4wD1Z5h8pJiH1KRqdDZEt4pqZ",
			}, {
				name: "使用统计",
				action: "showrefUseStatisticsBtn",
				token: "n_krfvxzs9MJXXlTDUiQpvl7izpmzT",
			}],
		},
	]);

	/**
	 * 新增优惠券按钮
	 * */
	const refCreateCoupons = ref(null)
	const showCreateCoupons = () => {
		refCreateCoupons.value.openAddCoupons();
	}

	/**
	 * 使用统计
	 */
	const refUseStatistics = ref(null)
	const showrefUseStatisticsBtn = (data) => {
		refUseStatistics.value.show(data.c_id);
	}

	const refIssuingVouchers = ref(null);  // 发券对话框
	/**
	 * 发券
	 */
	const provideCoupons = (e) => {
		refIssuingVouchers.value.openDialog(e);
		// ElMessageBox.confirm(
		// 		'确认要给符合条件的用户下发优惠券吗?一经确认不可撤销！<br/> <div style="color:#ff0000;font-size:12px">注意：生成数量不足时系统仍将下发优惠券并修改生成数量</div>',
		// 		'发券确认', {
		// 			dangerouslyUseHTMLString: true,
		// 			confirmButtonText: '确认',
		// 			cancelButtonText: '取消',
		// 		}
		// 	)
		// 	.then(() => {
		// 		Coupons.couponsDealt({
		// 			c_id: e.c_id
		// 		}).then(res => {
		// 			if (res.Code == 200) {
		// 				ElMessage({
		// 					type: 'success',
		// 					message: res.Message,
		// 				})
		// 			} else {
		// 				ElMessage.error(res.Message);
		// 			}
		// 		})
		// 	})
	}
	const onConfirm = (ids, row) => {
		Coupons.couponsDealt({
			c_id: row.c_id,
			m_id: ids,
		}).then(res => {
			if (res.Code == 200) {
				ElMessage.success(res.Message);
				roleTable.value.tableLoad();
			} else {
				ElMessage.error(res.Message);
			}
		})
	}
	const tableLoad = () => {
		roleTable.value.tableLoad();
	}
	onMounted(() => {
		tableLoad()
	});
</script>

<style lang="scss" scoped>
	.grey-txet {
		color: var(--text-color);
	 }
	.circle {
		height: 25px;
		width: 25px;
		border-radius: 4px;
		background-color: var(--menu-btn-color);
		color: var(--theme-color);
		text-align: center;
		line-height: 25px;
		margin-left: 5px;
		cursor: pointer;
	}

	.top-filter {
		display: flex;
		justify-content: space-between;
		margin: 10px 25px;
		padding: 10px 20px;
		background-color: #fff;
		border-radius: 5px;

		.search-input {
			width: 200px;
		}
	}

	.order-charge {
		font-family: "Source Han Sans CN";

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;
			}
		}

		.bg-color {
			background-color: #F4FDFA;
		}
	}

	.amount {
		margin-top: 10px;
		justify-content: space-between;
	}

	.amount-title {
		font-size: 18px;
	}

	.green-tag {
		background-color: #1AC994;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.orange-tag {
		background-color: #ffaa00;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.blue-tag {
		background-color: #00aaff;
		color: #fff;
		text-align: center;
		border-radius: 5px;
	}

	.total-paid {
		margin-right: 290px;
		color: #1AC994;
	}

	.order-left {
		margin-bottom: 20px;
		padding: 20px;
	}

	.charging-state {
		margin-bottom: 20px;
	}

	.charged {
		padding: 20px 30px;
	}

	.time-on {
		padding: 22px 20px;
	}

	.order-right {
		padding: 20px 15px !important;
	}

	.charging-img {
		width: 50px;
		height: 50px;
	}
</style>