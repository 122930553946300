<!-- 发券 -->

<template>
	<div class="certificate-dialog">
		<w-dialog ref="dialogRef" title="发券确认" width="32%" top="20vh" @wConfirm="addConfirm" :modalClick="false">
			<div class="flex justify-between">
				<div v-for="(item, i) in grantLists" :key="i" class="flex certificate-top"
					:class="activeIndex == i ? 'active' : ''" @click="onClickList(item, i)">
					<div class="certificate-icon">
						<img class="" :src="item.imgUrl" />
					</div>
					<div class="certificate-name">{{ item.name }}</div>
					<div :style="{'left': item.leftPx}" style="position: relative;top: -6px;" v-show="activeIndex == i">
						<img :src="selected" />
					</div>
				</div>
			</div>

			<div v-if="activeIndex == '0'">
				<div class="default-text">默认发放将给符合条件的所有用户发券</div>
				<div class="confirm-text">确认要给符合条件的用户下发优惠券吗？一经确认不可撤销！</div>
			</div>

			<div v-else>
				<div class="default-text">自定义发券只会给您指定的用户发券</div>
				<div>
					<div class="confirm-text">用户：</div>
					<el-select clearable filterable remote reserve-keyword v-model="keywords" placeholder="请输入用户手机号"
						remote-show-suffix :remote-method="searchManage" @change="changeManage">
						<el-option v-for="(item,index) in manageList" :key="index" :label="item.m_mobile"
							:value="item.m_id" />
					</el-select>
					<div class="">
						<el-button class="active-skin" v-for="(item,i) in onfirmManage" :key="i">
							<div class="del-btn" @click="delManage(i)">x</div>
							{{item.m_mobile}}
						</el-button>
					</div>
				</div>
			</div>

			<div class="pour-text">注意：生成数量不足时系统仍将下发优惠券并修改生成数量</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import { ref, } from "vue";
	import { SystemManage, } from "@/plugins/api.js";
	import more from '@/assets/img/more.png';
	import draw from '@/assets/img/draw.png';
	import selected from '@/assets/img/selected.png';
	import {
		unique,
		objToStirng
	} from "@/utils/common.js";
	import { ElMessage, } from "element-plus";

	// 弹框对象
	const dialogRef = ref(null);
	const currentRow = ref({})
	const grantLists = ref([
		{ id: '1', name: '默认发放', imgUrl: more, leftPx: '125px', },
		{ id: '2', name: '自定义发放', imgUrl: draw, leftPx: '112px', },
	]);
	const activeIndex = ref(0);  // 选择下标
	const keywords = ref('');  // 搜索内容
	const manageList = ref([]);
	const onfirmManage = ref([]);

	const emit = defineEmits(['onConfirm'])
	/**
	 * 
	 * 打开弹框
	 * 
	 * */
	const openDialog = (data) => {
		currentRow.value = data;
		activeIndex.value = 0;
		keywords.value = '';
		manageList.value = [];
		onfirmManage.value = [];
		dialogRef.value.show();
	};
	/**
	 * 
	 * 切换
	 * 
	 * */
	const onClickList = (item, i) => {
		activeIndex.value = i;
		keywords.value = '';
		manageList.value = [];
		onfirmManage.value = [];
	}
	/**
	 * 
	 * 搜索人员
	 * 
	 * */
	const searchManage = (e) => {
		SystemManage.mateMemberLists({ m_mobile: e, c_id: currentRow.value.c_id }).then(res => {
			if (res.Code == 200) {
				manageList.value = res.Data.list;
			}
		})
	}
	/**
	 * 
	 * 选择人员
	 * 
	 * */
	const changeManage = (e) => {
		if (manageList.value) {
			const row = manageList.value.filter(res => {
				if (res.m_id == e) {
					return res;
				}
			})
			if (row && row[0]) {
				onfirmManage.value.push(row[0]);
				onfirmManage.value = unique(onfirmManage.value);
			}
		}
	}
	/**
	 * 
	 * 删除人员
	 * 
	 * */
	const delManage = (i) => {
		onfirmManage.value.splice(i, 1)
	}
	/**
	 * 
	 * 确认新增按钮
	 * 
	 * */
	const addConfirm = () => {
		let ids = [];
		if (activeIndex.value == '1') {
			if (onfirmManage.value.length == 0) {
				ElMessage.error("请选择用户！");
				return false;
			} else {
				ids = onfirmManage.value.map(resp => {
					return resp.m_id;
				})
			}
		}
		dialogRef.value.close();
		emit("onConfirm", ids, currentRow.value);
	}
	defineExpose({
		openDialog
	})
</script>

<style lang="scss" scoped>
	.certificate-dialog {
		.flex {
			display: flex;
		}

		.justify-between {
			justify-content: space-between;
		}

		.certificate-top {
			padding: 15px 20px;
			border: 1px solid #ECECEC;
			border-radius: 6px;
			width: 265px;
		}

		.certificate-icon {
			padding: 4px 4px;
			border-radius: 7px;
			line-height: 12px;

			img {
				width: 22px;
				height: 22px;
			}
		}

		.certificate-name {
			font-size: 14px;
			font-weight: bold;
			line-height: 29px;
		}

		.active {
			border: 1px solid #56B99D;
		}

		.default-text {
			color: #AFAFB1;
			margin-top: 10px;
		}

		.confirm-text {
			font-size: 16px;
			font-weight: bold;
			color: #000;
			margin-top: 30px;
		}

		.pour-text {
			color: #ff0000;
			font-size: 12px;
			margin-top: 10px;
		}

		.active-skin {
			color: var(--theme-color) !important;
			background-color: var(--search-bg-color) !important;
			position: relative;
			margin-top: 10px;

			.del-btn {
				position: absolute;
				right: 0;
				top: 0;
				height: 15px;
				width: 20px;
				border-radius: 0 0 0 50%;
				background-color: rgba(0, 0, 0, 0.5);
				color: #fff;
			}
		}
	}
</style>