<!-- 选择商家 -->
<template>
	<w-dialog ref="refWdialog" class="auth-dialog" title="关联商家" width="60%" btnWidth="140px" top="10vh"
		confirmText="确认保存" @wConfirm="authConfirm" :modalClick="false">
		<div class="mcontent">
			<div class="flex">
				<div class="name s-m-r flex-center">所属应用</div>
				<div class="select">
					<el-select v-model="m_id" placeholder="请选择权限模块" @change="moduleChange">
						<el-option :label="item.name" :value="item.id" v-for="item in authModules" :key="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="checkbox-wp flex" v-if="treeData[0]">
				<div class="left-wp">
					<div class="tree-wp" v-loading="treeLoading" element-loading-text="数据加载中">
						<el-scrollbar style="height: 100%">
							<el-tree ref="tree" :data="treeData" :props="{children: 'child',label: 'name',}" accordion
								node-key="id" :default-expanded-keys="defaultExpanded" @node-click="nodeClick" />
						</el-scrollbar>
					</div>
				</div>
				<div class="right-wp">
					<div class="content">
						<el-table ref="tableref" :data="authTree" stripe style="width: 100%"
							element-loading-text="数据加载中" tooltip-effect="light"
							@selection-change="handleSelectionChange" @select="handleSelectChange" height='500'
							:row-key="getRowKeys">
							<el-table-column type="selection" width="55" :reserve-selection="true" />
							<el-table-column prop="name" label="电站名称" show-overflow-tooltip />
						</el-table>
					</div>
				</div>
			</div>
		</div>
	</w-dialog>
</template>

<script setup>
	import {
		ref
	} from 'vue'
	import {
		SystemManage,
	} from "@/plugins/api.js";
	import {
		ElMessage,
	} from "element-plus";
	// 模块列表
	const authModules = ref([]);
	const treeData = ref([]); // 树形数据
	const authTree = ref([]); // 权限范围
	const treeLoading = ref(false); // 树结构加载标识
	const defaultExpanded = ref([]); // 默认展开节点
	const currentRangeRow = ref(''); // 分配权限范围当前行数据
	const m_id = ref(''); // 所属应用
	const multipleSelection = ref([]); // 权限范围
	const tableref = ref(null); // 表格对象
	/**
	 * 分配权限范围确认按钮
	 * */
	const authId = ref([])
	const getAuthId = (data) => {
		data.forEach(resp => {
			if (resp.child) {
				getAuthId(resp.child)
			}
			if (resp.station) {
				getAuthId(resp.station)
			}
			if (resp.checked) {
				authId.value.push(resp.id)
			}
		})
	}
	const emits = defineEmits(['choose'])
	const authConfirm = () => {
		if(respId.value){
			authId.value = respId.value
		}else{
			authId.value = []
		}
		getAuthId(treeData.value)
		if (authId.value.length == 0) {
			ElMessage.error("请选择电站！");
			return;
		}
		refWdialog.value.close()
		emits('choose', authId.value)
	};

	/**
	 * 节点点击回调
	 * */
	const nodeClick = (node) => {
		if (node.station) {
			authTree.value = node.station.length > 0 ? node.station : [];
			// 回显已关联
			if (authTree.value.length > 0) {
				multipleSelection.value = []
				authTree.value.forEach(item => {
					if(respId.value.length != 0){
						respId.value.forEach(resp =>{
							if(item.id == resp){
								tableref.value.toggleRowSelection(item, true);
							}
						})
					}
					multipleSelection.value.push(item);
					if (item.checked) {
						tableref.value.toggleRowSelection(item, true);
					}
				})
			}
		} else {
			authTree.value = [];
		}
	};

	/**
	 * 模块列表
	 * */
	const getModuleLists = () => {
		SystemManage.getModuleLists().then((res) => {
			if (res.Code === 200) {
				authModules.value = res.Data ? res.Data : [];
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 选择所属应用
	 * */
	const moduleChange = () => {
		SystemManage.getModuleRangeLists({
			u_id: currentRangeRow.value.u_id,
			m_id: m_id.value
		}).then((res) => {
			if (res.Code === 200) {
				multipleSelection.value = [];
				treeData.value = res.Data ? res.Data : [];

			} else {
				ElMessage.error(res.Message);
			}
		});
	};

	/**
	 * 选择权限分组
	 * */

	const handleSelectionChange = (arr) => {
		multipleSelection.value.forEach(res => {
			if (arr.length == 0) {
				res.checked = false
			} else {
				arr.forEach(resp => {
					if (res.id == resp.id) {
						res.checked = true
					} else {
						res.checked = false
					}
				})
			}
		})
	}
	const handleSelectChange = (arr, val) => {
		val.checked = !val.checked
	}
	/**
	 * 表格选项
	 * */
	const getRowKeys = (row) => {
		return row.id;
	}

	const refWdialog = ref(null)
	const respId = ref([]) //回显选择的电站
	const openChooseMerchant = (data) => {
		if(data && data.length != 0){
			multipleSelection.value = [];
			treeData.value = []
			respId.value = data
			authModules.value = []
			m_id.value = []
			authTree.value = [];
		}else{
			multipleSelection.value = [];
			treeData.value = []
			respId.value = []
			authModules.value = []
			m_id.value = []
			authTree.value = [];
		}
		refWdialog.value.show();
		getModuleLists()
	}

	defineExpose({
		openChooseMerchant
	})
</script>

<style lang="scss">
	.left-wp {
		flex: 1;
		border-radius: 10px;
		overflow: hidden;
		background-color: #fff;
		padding: 20px 0;
		box-sizing: border-box;

		.tree-wp {
			.el-tree {
				padding: 0 15px;

				.el-tree-node {
					.el-tree-node__content {
						height: 40px !important;

						>label.el-checkbox {
							display: none;
						}

						.el-tree-node__expand-icon {
							font-size: 16px;
						}
					}

					.el-tree-node__content:hover {
						background-color: var(--search-bg-color);
					}
				}

				.el-tree-node:focus {
					>.el-tree-node__content {
						background-color: var(--search-bg-color);
					}
				}

				.el-tree-node.is-checked {
					>.el-tree-node__content {
						background-color: var(--search-bg-color);

						>.el-tree-node__label {
							color: var(--theme-color);
						}

						>.el-tree-node__expand-icon {
							color: var(--theme-color);
						}

						>.el-tree-node__expand-icon.is-leaf {
							color: transparent;
						}
					}
				}
			}
		}
	}

	.right-wp {
		flex: 3;
		border-radius: 10px;
		background-color: #fff;

		.content {
			padding: 20px;
		}
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>