<template>
	<div>
		<w-dialog ref="refWdialog" class="add-dialog" :modalClick="false" title="创建优惠券" width="70%" btnWidth="140px"
			top="5vh" @wConfirm="wConfirm" :confirmText="'确认'">
			<el-form labelPosition="top" ref="ruleFormRef"  v-loading="loading"  :model="ruleForm" :rules="rules">
				<!-- <div class="basic-title">
					<div class="flex-center">
						<el-icon>
							<Ticket />
						</el-icon>
					</div>

					基本信息
				</div> -->
				<w-title :title_name="'基本信息'"></w-title>
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="优惠券名称" prop="c_title">
							<el-input v-model="ruleForm.c_title" placeholder="请输入优惠券名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="生成数量" prop="c_num">
							<el-input v-model="ruleForm.c_num" type="number" placeholder="请输入生成数量"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="发放时间" prop="grant_time">
							<el-date-picker v-model="ruleForm.grant_time" type="daterange" range-separator="至"
								start-placeholder="开始时间" end-placeholder="结束时间" />
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="使用有效期" prop="c_period_type">
							<el-select v-model="ruleForm.c_period_type" clearable placeholder="请选择有效期">
								<el-option label="固定时间段" value="1"></el-option>
								<el-option label="领取后固定天数" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_period_type == 1">
						<el-form-item label="固定时间段" prop="use_time">
							<el-date-picker v-model="ruleForm.use_time" type="daterange" range-separator="至"
								start-placeholder="开始时间" end-placeholder="结束时间" />
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_period_type == 2">
						<el-form-item label="天数" prop="c_use_day">
							<el-input v-model="ruleForm.c_use_day" type="number" placeholder="请输入天数"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="是否允许叠加" prop="c_over">
							<el-select v-model="ruleForm.c_over" disabled placeholder="请选择是否允许叠加">
								<el-option label="不可叠加" value="1"></el-option>
								<el-option label="可叠加" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <div class="basic-title">
					<div class="flex-center">
						<el-icon>
							<Ticket />
						</el-icon>
					</div>
					其他信息
				</div> -->
				<w-title :title_name="'其他信息'"></w-title>
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="是否活动券" prop="c_use_way">
							<el-select v-model="ruleForm.c_use_way" clearable placeholder="请选择是否活动券" @change="ticketChange">
								<el-option label="否" value="1"></el-option>
								<el-option label="是" value="2"></el-option>
								<el-option label="会员券" value="3"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item label="优惠类型" prop="c_ct_id">
							<el-select v-model="ruleForm.c_ct_id" clearable placeholder="请选择优惠类型" @change="changeType">
								<el-option label="满减券" value="1"></el-option>
								<el-option label="折扣券" value="2"></el-option>
								<el-option label="立减券" value="3"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="8" v-if="ruleForm.c_ct_id == 1">
						<el-form-item label="满减规则" prop="c_nomal_factor">
							<div class="flex">
								<el-input v-model="ruleForm.c_nomal_factor" class="w-50 m-2" placeholder="满XX元" />
								<el-input v-model="ruleForm.c_spci_factor" style="margin-left: 10px;" class="w-50 m-2"
									placeholder="减XX元" />
							</div>
						</el-form-item>
					</el-col>

					<el-col :span="8" v-if="ruleForm.c_ct_id == 2">
						<el-form-item label="折扣" prop="c_nomal_factor">
							<el-input v-model="ruleForm.c_nomal_factor" type="number" placeholder="请输入折扣"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_ct_id == 2">
						<el-form-item label="最高优惠" prop="c_spci_factor">
							<el-input v-model="ruleForm.c_spci_factor" type="number" placeholder="请输入最高优惠"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="8" v-if="ruleForm.c_ct_id == 3">
						<el-form-item label="立减规则" prop="c_nomal_factor">
							<el-input v-model="ruleForm.c_nomal_factor" type="number" placeholder="请输入立减"></el-input>
						</el-form-item>
					</el-col>


					<el-col :span="8">
						<el-form-item label="适用范围" prop="c_range" style="position: relative;">
							<el-select v-model="ruleForm.c_range" @change="changeRange" clearable placeholder="请选择适用范围">
								<el-option label="平台券" value="1"></el-option>
								<el-option label="商家券" value="2"></el-option>
							</el-select>
							<div class="circle" @click.stop="showRange" v-if="ruleForm.c_organ.length > 0">
								{{ruleForm.c_organ.length}}
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="发放类型" prop="c_grant_type">
							<el-select v-model="ruleForm.c_grant_type" clearable placeholder="请选择发放类型"
								:disabled="ruleForm.c_use_way == '2'" @change="ruleForm.c_grant_way = ''">
								<el-option label="平台发放" value="1"></el-option>
								<el-option label="主动领取" value="2"></el-option>
								<el-option label="链接领取" value="3"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_grant_type == 1">
						<el-form-item label="触发类型" prop="c_grant_way">
							<el-select v-model="ruleForm.c_grant_way" clearable placeholder="请选择触发类型"
								:disabled="ruleForm.c_use_way == '2'" @change="ruleForm.c_grant_money = ''">
								<el-option label="手动触发" value="1"></el-option>
								<el-option label="注册触发" value="2"></el-option>
								<el-option label="消费触发" value="3" v-if="ruleForm.c_ct_id == '3'"></el-option>
								<el-option label="自动触发" value="4"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_grant_way == 3">
						<el-form-item label="消费金额" prop="c_grant_money">
							<el-input v-model="ruleForm.c_grant_money" type="number" placeholder="请输入消费金额"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="适用对象" prop="c_target">
							<el-select v-model="ruleForm.c_target" clearable placeholder="请选择适用对象">
								<el-option label="不限制" value="1" v-if="ruleForm.c_range == 1"></el-option>
								<el-option label="新用户" value="2" v-if="ruleForm.c_range == 1"></el-option>
								<el-option label="老用户" value="3"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="领券限制" prop="c_limit">
							<el-select v-model="ruleForm.c_limit" clearable placeholder="请选择领券限制">
								<el-option label="单日限制" value="1"></el-option>
								<el-option label="总量限制" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_limit == 2">
						<el-form-item label="总量限制" prop="c_limit_factor">
							<el-input v-model="ruleForm.c_limit_factor" type="number"
								placeholder="请输入总计限制张数"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="ruleForm.c_limit == 1">
						<el-form-item label="单日限制" prop="c_limit_factor">
							<el-input v-model="ruleForm.c_limit_factor" type="number"
								placeholder="请输入单日限制张数"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <div class="basic-title">
					<div class="flex-center">
						<el-icon>
							<Ticket />
						</el-icon>
					</div>
					备注
				</div> -->
				<w-title :title_name="'备注'"></w-title>
				<el-form-item label="备注" prop="c_remark">
					<el-input v-model.trim="ruleForm.c_remark" type="textarea" rows="3" placeholder="请输入备注说明">
					</el-input>
				</el-form-item>
			</el-form>
		</w-dialog>

		<chooseMerchant ref="refChooseMerchant" @choose="choose"></chooseMerchant>
		<assPlatform ref="refAssPlatform" @assConfirm="assConfirm"></assPlatform>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref
	} from 'vue';
	import {
		Ticket
	} from "@element-plus/icons-vue";
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		Coupons,
	} from "@/plugins/api.js";

	import {
		timeFormat
	} from '@/utils/common.js'

	import chooseMerchant from './chooseMerchant.vue'
	import assPlatform from './assPlatform.vue'
	import {
		nextTick
	} from 'vue';
	const props = defineProps({
		tableLoad: {
			type: Function,
			default: null
		}
	})

	const ruleFormRef = ref(null)
	const formEl = ruleFormRef.value

	const ruleForm = reactive({
		c_title: '', //优惠券标题
		c_num: '', //优惠券数量
		c_grant_stime: '', //发放开始时间
		c_grant_etime: '', //发放结束时间
		c_period_type: '', //有效期方式 1：固定时间段 2：领取后固定天数
		c_use_stime: '',
		c_use_etime: '',
		c_use_day: '',
		c_over: '1', //是否叠加 1：不可叠加 2：可叠加
		c_ct_id: '', //1：满减券 2：折扣券 3：立减券
		c_nomal_factor: '', //c_ct_id = 1 或 c_ct_id = 3 必填
		c_spci_factor: '', //c_ct_id = 1 或 c_ct_id = 2 选填
		c_range: '', //适用范围 1：平台券 2：商家券
		c_organ: [], //数组如：["1","2"]
		c_grant_type: '', //发放类型：1：平台发放 2：自动领取
		c_grant_way: '', //c_grant_type = 1 必填 1：手动触发 2：注册厨房 3：消费触发 4：自动触发
		c_grant_money: '',  // 消费金额  触发类型为消费触发显示
		c_target: '', //适用对象： 1： 不限制 2：新用户 3：老用户
		c_limit: '', //限制：1：单日限制 2：总量限制
		c_limit_factor: '', //限制张数
		grant_time: '',
		use_time: '',
		c_use_way: '',  // 是否活动券
		c_remark: '',  // 备注
	})


	//验证天数
	const changeUseDay = (rule, value, callback) => {
		if (!value) {
			return callback(new Error('请输入天数'))
		}
		setTimeout(() => {
			if (!Number(value)) {
				callback(new Error('请输入数字'))
			} else {
				if (value < 0) {
					callback(new Error('必须大于0'))
				} else {
					callback()
				}
			}
		}, 500)
	}

	const validatyRange = (rule, value, callback) => {
		setTimeout(() => {
			if (!value) {
				callback(new Error('请选择适用范围'))
			} else {
				if (value == 1 && ruleForm.c_organ.length == 0) {
					return callback(new Error('请选择平台'))
				} else if (value == 2 && ruleForm.c_organ.length == 0) {
					return callback(new Error('请选择商家'))
				} else {
					callback()
				}
			}
		}, 200)
	}

	const rules = reactive({

		c_title: [{
			required: true,
			message: '请输入优惠券标题',
			trigger: 'blur'
		}, ],
		c_num: [{
			required: true,
			message: '请输入优惠券数量',
			trigger: 'blur'
		}, ],
		grant_time: [{
			required: true,
			message: '请选择发放时间',
			trigger: 'change'
		}],
		c_period_type: [{
			required: true,
			message: '请选择有效期方式',
			trigger: 'change'
		}, ],
		c_over: [{
			required: true,
			message: '请选择是否叠加',
			trigger: 'change'
		}, ],
		c_ct_id: [{
			required: true,
			message: '请选择折扣类型',
			trigger: 'change'
		}, ],
		c_use_way: [{
			required: true,
			message: '请选择是否活动券',
			trigger: 'change'
		}, ],
		c_range: [{
			required: true,
			validator: validatyRange,
			trigger: 'change'
		}, ],
		c_grant_type: [{
			required: true,
			message: '请选择发放类型',
			trigger: 'change'
		}, ],
		c_target: [{
			required: true,
			message: '请选择适用对象',
			trigger: 'change'
		}, ],
		c_limit: [{
			required: true,
			message: '请选择限制',
			trigger: 'change'
		}, ],
		c_limit_factor: [{
			required: true,
			message: '请输入限制张数',
			trigger: 'blur'
		}, ],
		c_grant_way: [{
			required: true,
			message: '请选择触发类型',
			trigger: 'change'
		}],
		c_grant_money: [{
			required: true,
			message: '请输入消费金额',
			trigger: 'blur'
		}],
		c_use_day: [{
			required: true,
			validator: changeUseDay,
			trigger: 'blur'
		}],
		use_time: [{
			required: true,
			message: '请选择固定时间段',
			trigger: 'change'
		}]
	})


	const refWdialog = ref(null)
	const openAddCoupons = () => {
		resetForm()
		refWdialog.value.show()
		nextTick(() => {
			ruleFormRef.value.resetFields()
		})

	}
	const resetForm = () => {
		ruleForm.c_title = ''; //优惠券标题
		ruleForm.c_num = '' //优惠券数量
		ruleForm.c_grant_stime = '' //发放开始时间
		ruleForm.c_grant_etime = '' //发放结束时间
		ruleForm.c_period_type = '' //有效期方式 1：固定时间段 2：领取后固定天数
		ruleForm.c_use_stime = ''
		ruleForm.c_use_etime = ''
		ruleForm.c_use_day = ''
		ruleForm.c_over = '1' //是否叠加 1：不可叠加 2：可叠加
		ruleForm.c_ct_id = '' //1：满减券 2：折扣券 3：立减券
		ruleForm.c_nomal_factor = '' //c_ct_id = 1 或 c_ct_id = 3 必填
		ruleForm.c_spci_factor = '' //c_ct_id = 1 或 c_ct_id = 2 选填
		ruleForm.c_range = '' //适用范围 1：平台券 2：商家券
		ruleForm.c_organ = [] //数组如：["1","2"]
		ruleForm.c_grant_type = '' //发放类型：1：平台发放 2：自动领取
		ruleForm.c_grant_way = '' //c_grant_type = 1 必填 1：手动触发 2：注册厨房 3：消费触发 4：自动触发
		ruleForm.c_grant_money = '',  // 消费金额
		ruleForm.c_target = '' //适用对象： 1： 不限制 2：新用户 3：老用户
		ruleForm.c_limit = '' //限制：1：单日限制 2：总量限制
		ruleForm.c_limit_factor = '' //限制张数
		ruleForm.grant_time = ''
		ruleForm.use_time=''
		ruleForm.c_use_way = ''
		ruleForm.c_remark = '';
	}

	const refChooseMerchant = ref(null)
	const refAssPlatform = ref(null)
	//选择适用范围
	const changeRange = () => {
		ruleForm.c_target = ''
		ruleForm.c_organ = []
		if (ruleForm.c_range == 2) { // 适用范围为商家
			refChooseMerchant.value.openChooseMerchant()
		} else if (ruleForm.c_range == 1) { //适用范围平台
			refAssPlatform.value.openDialog()
		}
	}
	const showRange = () => {
		if (ruleForm.c_range == 2) { // 适用范围为商家
			refChooseMerchant.value.openChooseMerchant(ruleForm.c_organ)
		} else if (ruleForm.c_range == 1) { //适用范围平台
			refAssPlatform.value.openDialog(ruleForm.c_organ)
		}
	}
	const loading = ref(false)

	const wConfirm = async () => {
		if (ruleForm.grant_time) {
			ruleForm.c_grant_stime = timeFormat(ruleForm.grant_time[0])
			ruleForm.c_grant_etime = timeFormat(ruleForm.grant_time[1])
		}
		if (ruleForm.use_time) {
			ruleForm.c_use_stime = timeFormat(ruleForm.use_time[0])
			ruleForm.c_use_etime = timeFormat(ruleForm.use_time[1])
		}
		loading.value = true
		await ruleFormRef.value.validate((valid, fields) => {
			if (valid) {
				Coupons.couponsAdd(ruleForm).then(res => {
					loading.value = false
					if (res.Code == 200) {
						ruleFormRef.value.resetFields()
						ElMessage.success('新增成功');
						refWdialog.value.close()
						props.tableLoad()
					} else {
						ElMessage.error(res.Message);
					}
				})
			}else{
				loading.value = false
			}
		})

	}
	const assConfirm = (platform) => { //选择平台
		ruleForm.c_organ = platform
	}
	const choose = (merchant) => { //选择商户
		ruleForm.c_organ = merchant
	}
	/**
	 *
	 * 选择优惠类型
	 *
	 * **/
	const changeType = () => {
		ruleForm.c_nomal_factor = '';
		ruleForm.c_spci_factor = '';
		ruleForm.c_grant_way = '';
	}
	/**
	 *
	 * 选择是否活动券
	 *
	 * **/
	const ticketChange = () => {
		if(ruleForm.c_use_way == '2') {
			ruleForm.c_grant_type = '1';
			ruleForm.c_grant_way = '4';
		} else {
			ruleForm.c_grant_type = '';
			ruleForm.c_grant_way = '';
		}
	}
	defineExpose({
		openAddCoupons
	})
</script>

<style lang="scss" scoped>
	.basic-title {
		color: var(--theme-color);
		padding-bottom: 10px;
		display: flex;
	}

	.circle {
		z-index: 999;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: var(--theme-color);
		color: #fff;
		text-align: center;
		line-height: 20px;
		margin-left: 5px;
		position: absolute;
		left: 60px;
		top: 11px;
		cursor: pointer;
	}
</style>