<!-- 关联平台 -->
<template>
	<div class="power-detail-dialog">
		<w-dialog ref="dialogRef" title="关联平台" width="60%" top="6vh" @wConfirm="addConfirm" :modalClick="false">
			<el-table ref="tableref" :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中"
				tooltip-effect="light" @selection-change="handleSelectionChange" height='450' :row-key="getRowKeys">
				<el-table-column type="selection" width="55" :reserve-selection="true" />
				<el-table-column prop="mi_id" label="小程序ID" show-overflow-tooltip />
				<el-table-column prop="mi_name" label="小程序名称" show-overflow-tooltip />
			</el-table>
		</w-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
	} from "vue";
	import {
		SystemManage,
	} from "@/plugins/api.js";
	import {
		ElMessage,
	} from "element-plus";

	// 弹框对象
	const dialogRef = ref(null);
	const tableref = ref(null);
	const tableDatas = ref([]); // 表格数据
	const multipleSelection = ref([]); // 选中分组

	const emit = defineEmits(['assConfirm'])
	/**
	 * 
	 * 打开弹框
	 * 
	 * */
	const respId = ref([])
	const openDialog = (data) => {
		if (data && data.length != 0) {
			respId.value = data
		}
		multipleSelection.value = [];
		getPlatformList();
		dialogRef.value.show();
	};
	/**
	 * 
	 * 获取分组列表数据
	 * 
	 * */
	const getPlatformList = () => {
		SystemManage.platformList({}).then((res) => {
			if (res.Code === 200) {
				tableDatas.value = res.Data ? res.Data : [];

				// // 回显已关联
				if (tableDatas.value.length > 0) {
					tableDatas.value.forEach(item => {
						if (respId.value.length != 0) {
							respId.value.forEach(resp => {
								if (item.mi_id == resp) {
									multipleSelection.value.push(item);
									tableref.value.toggleRowSelection(item, true);
								}
							})
						}
					})
				}
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 选择权限分组
	 * 
	 * */
	const handleSelectionChange = (val) => {
		multipleSelection.value = val;
	}
	/**
	 * 
	 * 确认新增按钮
	 * 
	 * */
	const addConfirm = () => {
		if (multipleSelection.value.length < 1) {
			ElMessage.error("请选择平台！");
			return;
		}
		let ids = multipleSelection.value.map(resp => {
			return resp.mi_id
		})
		dialogRef.value.close();
		emit("assConfirm", ids);
	}
	const getRowKeys = (row) => {
		return row.id;
	}
	defineExpose({
		openDialog
	})
</script>

<style lang="scss">

</style>